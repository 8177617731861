import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage27() {
  const question = {
    title: 'Frage 27 von 35',
    headline: '',
    subline: 'Mich bringt nichts so schnell aus der Ruhe.',
  }

  const answers = [
    {
      name: 'Ausgeglichenheit',
      id: 'definitiv',
      value: 'Definitiv. Ich mag es, wenn es auch mal heiß her geht.',
      desc: '',
    },
    {
      name: 'Ausgeglichenheit',
      id: 'klappt',
      value: 'Klappt fast immer.',
      desc: '',
    },
    {
      name: 'Ausgeglichenheit',
      id: 'in-ruhe',
      value: 'Ich mache Dinge lieber in Ruhe und konzentriert.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-red-circles">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="red"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="turkis"
              index={27}
            />
          </div>
        </section>

        <MatchingNavigation
          color="red"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-28`}
          prev={`${AREA_CARE_PATH}/spielen/frage-26`}
          index={27}
        />

        <Icon position="3" name="ekg" />
      </main>
    </>
  )
}
